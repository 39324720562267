<template>
	<div>
		<div v-if="loading">...Loading</div>
		<div v-if="!loading">
			<div class="margin-bottom-20">
				<p><b>Customer:</b></p>
			</div>
			<div>
				<b-button class="is-primary margin-top-10" @click="onClose">
					Close
				</b-button>
			</div>
			<div class="text-bolder table-color">
				<div>
					<p>
						<b>Beginning Date:</b> {{ getDates().bd }} - <b>Ending Date:</b>
						{{ getDates().ed }}
					</p>
				</div>
				<p class="margin-top-10">
					<b>dd:</b> difference (days) &ensp; <b>np:</b> not paid &ensp;
					<b>ik:</b> sent to 'Finans'
				</p>
			</div>
			<div class="margin-bottom-30 margin-top-10">
				<ve-table
					:rows="rowsList"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:sort-options="sortOptions"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import storeCustomerHistory from '@/_srcv2/pages/admin/customer-history/store/useCustomerHistoryStore.js'
import Store from '@/store'
import GetCustomersHistoryQuery from '@/_srcv2/pages/admin/customer-history/graphql/GetCustomersHistory.graphql'
import dateFormat from 'dateformat'
export default {
	name: 'CustomerInvoices',
	setup() {
		const subtractYears = (numOfYears, date = new Date()) => {
			date.setFullYear(date.getFullYear() - numOfYears)
			return date
		}
		const getDates = (now = new Date()) => {
			const date = dateFormat(now, 'isoDate')
			const endingDate = date
			const tomorrow = now
			console.log('ending date: ', endingDate, 'tomorrow', tomorrow)
			tomorrow.setDate(tomorrow.getDate() + 1)
			const beginningDate = dateFormat(subtractYears(1, tomorrow), 'isoDate')
			console.log('beginningDate', beginningDate)
			return {
				ed: endingDate,
				bd: beginningDate,
			}
		}
		console.log(getDates())
		onMounted(() => {
			console.log('onMounted is fired')
			refetch()
		})
		// * Apollo Query -------------------------------------------------------------------------
		const { customer } = storeCustomerHistory()
		console.log('customer: ', customer)
		const variablesForQuery = reactive({
			our_company: Store.getters.getUserCurrentCompany,
			customer_id: computed(() => customer.value.customer_id),
			bd: getDates().bd,
			ed: getDates().ed,
		})
		// *----------------------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch, onResult } = useQuery(
			GetCustomersHistoryQuery,
			() => variablesForQuery,
			options,
		)
		// *----------------------------------------------------------------------------------------
		const columnsList = ref([
			{
				label: 'Invoice No',
				field: 'invoiceNo',
				width: '98px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '205px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '108px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '108px',
			},
			{
				label: 'Reminder No',
				field: 'reminderNum',
				width: '98px',
			},
			{
				label: 'Reminder Date',
				field: 'reminderDate',
				width: '108px',
			},
			{
				label: 'Reminder Due Date',
				field: 'reminderDueDate',
				width: '118px',
			},
			{
				label: 'Payment Date (latest)',
				field: 'paymentDate',
				width: '108px',
			},
			{
				label: 'dd',
				field: 'difference',
				width: '50px',
			},
			{
				label: 'np',
				field: 'isInvoicePaid',
				width: '50px',
			},
			{
				label: 'ik',
				field: 'isSentToInkasso',
				width: '48px',
			},
		])
		const rowsList = ref([])
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNo', type: 'desc' },
		}
		// *------------------------------------------------------------------------
		const timeDifference = ref()
		onResult((result) => {
			console.log('onResult', result?.data?.document_transactions)
			rowsList.value = result?.data?.document_transactions?.map((item) => {
				const paymentDate =
					item?.invoice_payments_aggregate?.aggregate?.max?.payment_date
				const invoiceDueDate = item.invoice_due_date
				function difference(date1, date2) {
					const date1utc = Date.UTC(
						date1.getFullYear(),
						date1.getMonth(),
						date1.getDate(),
					)
					const date2utc = Date.UTC(
						date2.getFullYear(),
						date2.getMonth(),
						date2.getDate(),
					)
					const day = 1000 * 60 * 60 * 24
					return (date2utc - date1utc) / day
				}
				if (paymentDate !== null || undefined) {
					const date1 = new Date(invoiceDueDate)
					const date2 = new Date(paymentDate)
					timeDifference.value = difference(date1, date2)
				} else {
					timeDifference.value = ''
				}
				return {
					invoiceNo: item.invoice_number,
					customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
					invoiceDate: item.invoice_date,
					invoiceDueDate: invoiceDueDate,
					reminderNum: item?.reminder_lines?.reminder_header?.reminder_id,
					reminderDate: item?.reminder_lines?.reminder_header?.reminder_date,
					reminderDueDate:
						item?.reminder_lines?.reminder_header?.reminder_due_date,
					paymentDate: paymentDate,
					ourCompany: item.our_company,
					customerId: item.customer.customer_id,
					difference: timeDifference.value,
					isInvoicePaid: item.is_invoice_paid === 'false' ? '*' : '',
					isSentToInkasso: item.is_sent_in_the_bailiffs === true ? '*' : '',
				}
			})
		})
		// ---------------------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'invoiceNo') {
				console.log(params.row)
				// storeDataAndGetReport(params.row)
			} else if (params.column.field === 'reminderNo') {
				console.log(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
			// storeDataAndGetReport(params.row)
		}
		// * ------------------------------------------------------------------------------
		const { showSearch, showHistory } = storeCustomerHistory()
		const onClose = () => {
			showHistory.value = false
			showSearch.value = true
		}
		return {
			sortOptions,
			loading,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
			getDates,
			onClose,
		}
	},
}
</script>

<style lang="scss" scoped></style>
